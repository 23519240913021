import { navigate } from "gatsby";
import moment from "moment";
import React, { useState } from "react";
import ReviewDetailForm from "../Components/ReviewForm/ReviewDetailForm";
import ReviewFormHeader from "../Components/ReviewForm/ReviewFormHeader";

const HoliGuestData = () => {
  const initialValues = {
    otherData: null,
  };
  const [reviewFormData, setReviewFormData] = useState(initialValues);

  const [isSave, setIsSave] = useState(false);
  const [dataError, setDataError] = useState();

  const onFinish = () => {
    let data = {
      branch: "ametrine24",
      Name: reviewFormData.otherData.name,
      Phone_Number: reviewFormData.otherData.mobileNo,
      Date_of_Birth: reviewFormData.otherData.dateOfBirth,
      Location: reviewFormData.otherData.location,
    };

    let tempData = {
      Name: data.Name,
      Date_of_Birth: data.Date_of_Birth,
      Location: data.Location,
      Phone_Number: data.Phone_Number,
      CreateDTTM: moment().format("YYYY-MM-DD HH:mm:ss"),
    };
    if (
      tempData.Date_of_Birth !== "" &&
      tempData.Name !== "" &&
      tempData.Mobile_No !== "" &&
      tempData.No_Of_PAX !== ""
    ) {
      fetch(
        "https://script.google.com/macros/s/AKfycbwm3ezMCYWQAJwEIUIddTlTtfSu5hC7zsIe_LepqOrFCixlvGna1BFRqwcrLk9rnfkoSQ/exec?action=ametrine_24",
        {
          method: "POST",
          body: JSON.stringify(tempData),
          mode: "cors",
        }
      )
        .then((response) => {
          if (response.status === 200) {
            navigate(`/thank-you?form=${"feedback"}`);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
      setReviewFormData(initialValues);
      setIsSave(!isSave);
    } else {
      if (
        tempData.Date_of_Birth === "" ||
        tempData.Name === "" ||
        tempData.Phone_Number === "" ||
        tempData.Location === ""
      ) {
        setDataError("Please fill the fields that are marked in red *");
      }
    }
  };
  return (
    <div className="max-w-lg mx-auto">
      <div className="bg-holi bg-no-repeat bg-center bg-cover">
        <div className="bg-opacity-50 bg-secondary-lightblue-100">
          <div
            className={"h-screen z-10"}
            style={{
              //   backgroundColor: "#e1f3fd",
              color: "#21659b",
            }}
          >
            <div className="mx-auto max-w-6xl">
              <div className="md:px-14 px-6">
                <ReviewFormHeader
                  branch={"ametrine_24"}
                  formType={"GuestData"}
                />
                <ReviewDetailForm
                  // branch={"ametrine_24"}
                  formType={"GuestData"}
                  guestDataType={"Holi"}
                  //   data={isSave}
                  otherDetail={(data) => {
                    setReviewFormData({ ...reviewFormData, otherData: data });
                    setDataError();
                  }}
                />
              </div>
              {dataError && (
                <div className="md:px-14 px-6 pb-4">{dataError}</div>
              )}
              <div className="md:px-14 px-6 block lg:flex justify-start w-full pb-5">
                <button
                  style={{
                    backgroundColor: "#21659b",
                    color: "#e1f3fd",
                  }}
                  className="rounded px-8 py-2 text-white font-semibold w-full outline-none focus:ring-1 ring-yellow-600"
                  onClick={() => {
                    onFinish();
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HoliGuestData;
